<template>
    <content-wrapper>
        <media-table
            v-model="selectedItems"
            :payload="payload"
            searchable
            editable
            viewable
        />
    </content-wrapper>
</template>

<script>
/* eslint-disable */
import ContentWrapper from '@/components/ContentWrapper'
import MediaTable from '@apps/media/components/MediaTable'
export default {
    name: 'Docs',
    components: { MediaTable, ContentWrapper },
    computed: {
        authUuid() {
            return this.$auth.user.uuid
        },
        payload() {
            return {
                author: this.authUuid
            }
        }
    },
    data() {
        return {
            selectedItems: []
        }
    }
}
</script>

<style lang=scss>

</style>
